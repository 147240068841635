<template>
  <div class="pa-6 ma-4">
    <div>
      <p class="text-2xl mb-6">CA journalier par partenaire</p>
      <filters :showGroupCASwitch="false"></filters>
    </div>
    <v-row>
      <v-col cols="12">
        <ca-by-partner-view-separated-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></ca-by-partner-view-separated-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/views/filters/general/Filters";
import CaByPartnerViewSeparatedChart from "@/views/cards/premium/CaByPartnerViewSeparatedChart";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "CaByPartnerView",
  components: {
    Filters,
    CaByPartnerViewSeparatedChart,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    let monthDate = new Intl.DateTimeFormat("fr-FR")
      .format(new Date())
      .split("/");
    this.$store.dispatch(
      "premium/updateMonthDate",
      `${monthDate[2]}-${monthDate[1]}`
    );
    const month = this.$route.query.month;
    let siteGroups = this.$route.query.siteGroups;
    if (month) {
      await this.$store.dispatch("premium/updateMonthDate", month);
    }
    if (siteGroups) {
      siteGroups = siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    monthDate() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnersConfigs() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
};
</script>
